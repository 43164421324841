/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
		const
			$body = $('body')
			;


		/*
		|
		| Values
		|-----------
		*/
		if ($(".values").length) {
			jQuery(document).ready(function ($) {

				jQuery(".values .item").on("mouseover", function () {
					jQuery('.values .item').removeClass('active');
					jQuery(this).addClass('active');

					let bg = $(this).attr('data-img');
					$(".bg-values").css("background-image", "url(" + bg + ")");
				});
				jQuery('.values .back').on('click', function () {
					jQuery('.values .item').removeClass('active');
					jQuery(this).addClass('active');

					let bg = $(this).attr('data-img');
					$(".bg-values").css("background-image", "url(" + bg + ")");
				});

			});
		}

		/*
		|
		| History
		|-----------
		*/
		if ($(".history").length) {
			jQuery(document).ready(function ($) {

				jQuery(".block-history .item").on("mouseover", function () {
					jQuery('.block-history .item').removeClass('active');
					jQuery(this).addClass('active');

					let bg_hist = $(this).attr('data-img');
					let title_hist = $(this).attr('data-title');
					let text_hist = $(this).attr('data-text');

					$(".bg-history").css("background-image", "url(" + bg_hist + ")");
					$(".block-title").html(title_hist);
					$(".block-text").html(text_hist);

				});
				jQuery('.block-history .back').on('click', function () {
					jQuery('.block-history .item').removeClass('active');
					jQuery(this).addClass('active');

					let bg_hist = $(this).attr('data-img');
					let title_hist = $(this).attr('data-title');
					let text_hist = $(this).attr('data-text');

					$(".bg-history").css("background-image", "url(" + bg_hist + ")");
					$(".block-title").html(title_hist);
					$(".block-text").html(text_hist);
				});

			});
		}


		let swiperslideseta = new Swiper("#swiper-slideseta", {
			spaceBetween: 15,
			slidesPerView: 3.3,
			speed: 1000,
			breakpoints: {
				0: {
					slidesPerView: 1.2,
				},
				300: {
					slidesPerView: 1.2,
				},
				480: {
					slidesPerView: 2.2,
				},
				768: {
					slidesPerView: 2.2,
				},
				992: {
					slidesPerView: 2.5,
				},
				1200: {
					slidesPerView: 2.5,
				},
				1400: {
					slidesPerView: 3.3,
				}
			},
			navigation: {
				nextEl: '.button-next',
				prevEl: '.button-prev'
			}
		});

		/*
		|
		| Gallery
		|-----------
		*/
		let slidesgallery = new Swiper("#slides-gallery", {
			speed: 1000,
			autoplay:
			{
				delay: 2000,
			},
			loop: true,
			pauseOnHover: false,
			navigation: {
				nextEl: '.button-next',
				prevEl: '.button-prev'
			}
		});

		/*
		|
		| Gallery activités
		|-----------
		*/
		let slidesgalleryactiv = new Swiper("#slides-gallery-activ", {
			speed: 1000,
			autoplay:
			{
				delay: 2000,
			},
			loop: true,
			pauseOnHover: false,
			spaceBetween: 15,
			centeredSlides: true,
			slidesPerView: 5.3,
			breakpoints: {
				0: {
					slidesPerView: 2.2,
				},
				768: {
					slidesPerView: 2.2,
				},
				992: {
					slidesPerView: 4.5,
				},
				1200: {
					slidesPerView: 4.5,
				},
				1400: {
					slidesPerView: 5.3,
				}
			},
			navigation: {
				nextEl: '.button-next',
				prevEl: '.button-prev'
			}
		});

		/*
		|
		| Slider Engagements
		|-----------
		*/

		const $sliderTesti = $('.block-slider-engag');

		if ($sliderTesti.length >= 1) {
			$sliderTesti.each(function () {
				let $slider = $(this);
				let slidertext = new Swiper($slider.find('.swiper-container'), {
					speed: 1000,
					pauseOnHover: false,
					slidesPerView: 1.1,
					spaceBetween: 15,
					navigation: {
						nextEl: $slider.find('.button-next'),
						prevEl: $slider.find('.button-prev')
					},
					breakpoints: {
						992: {
							spaceBetween: 15
						}
					}
				});
			});
		}

		/*
		| Modal actu
		|-----------------
		*/
		if ($(".iziModal").length) {
			$(".iziModal").iziModal({
				headerColor: "#011A24",
				transitionIn: "fade",
				bodyOverflow: true,
			});
			$body.on("click", ".icon-close", function (event) {
				$(".iziModal").iziModal("close");
			});
		}

		/*
		| 
		| Submenu
		|-----------
		*/
		if ($(window).width() > 1200) {
			$('.nav-drop', this).not('.in .nav-drop').stop(true, true).slideUp("10");
			$(".menu-item-has-children").hover(
				function () {
					$('.nav-drop', this).not('.in .nav-drop').stop(true, true).delay(100).slideDown("10");
					$(this).toggleClass('open');
				},
				function () {
					$('.nav-drop', this).not('.in .nav-drop').stop(true, true).delay(100).slideUp("10");
					$(this).toggleClass('open');
				}
			);
		}

		/*
		|
		| Menu sticky
		|-------------------------
		*/
		function minimenu() {
			if ($(window).scrollTop() > 50) {
				$('#header').addClass('sticky-menu');
			} else {
				$('#header').removeClass('sticky-menu');
			}
		}

		minimenu();
		$(window).scroll(function () {
			minimenu();
		});

	}
}
